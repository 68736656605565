import * as React from "react"
import styled from "styled-components"
import { size } from "../util/breakpoints"
import { BsCupStraw, BsBag, BsCameraReels } from "react-icons/bs"
import { FaGifts, FaTshirt, FaGlasses, FaFilm, FaSocks } from "react-icons/fa"
import { TbHanger } from "react-icons/tb"
import { AiOutlineCoffee, AiOutlineShopping } from "react-icons/ai"

const DescriptionStyle = styled.div`
  width: 100%;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5em 9em;
  background: #111;

  @media (max-width: ${size.laptopL}) {
    padding: 1em;
  }

  @media (max-width: ${size.tablet}) {
    padding: 1em;
  }

  @media (max-width: ${size.mobileL}) {
    padding: 2em 0;
  }

  img {
    max-width: 90%;
    margin: 0 auto;

    @media (max-width: ${size.tablet}) {
      min-width: 90%;
    }
  }

  h1 {
    display: flex;
    flex-direction: column;
    color: #eee;
    text-align: center;
    z-index: 999;
    font-size: 4vw !important;
    margin: 20px auto;
    margin-top: 0;
    width: fit-content;

    @media (max-width: ${size.laptopL}) {
      font-size: 2.4em !important;
    }

    @media (max-width: ${size.tablet}) {
      font-size: 2em !important;
    }
  }

  h2 {
    color: #fff;
    text-align: center;
    line-height: 1.75em;
    z-index: 999;
    font-size: 4vw;
    font-weight: lighter !important;
    max-width: 75%;
    margin: 10px auto;
    margin-top: 0;

    @media (max-width: ${size.laptopL}) {
      font-size: 2.5vw !important;
    }

    @media (max-width: ${size.tablet}) {
      font-size: 3vw !important;
    }

    @media (max-width: ${size.mobileL}) {
      font-size: 1.3em !important;
    }
  }

  img {
    max-width: 700px;

    @media (max-width: ${size.laptop}) {
      max-width: 500px;
    }

    @media (max-width: ${size.tablet}) {
      max-width: 380px;
    }

    @media (max-width: ${size.mobileL}) {
      max-width: 300px;
    }
  }

  .bg {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    overflow-y: hidden !important;

    .icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      color: #2aace0;
      margin: 0 auto;
      overflow-y: hidden !important;

      svg {
        opacity: 0.135;
        font-size: 4em;
        margin: 0.4em;
      }

      @media (max-width: ${size.laptopL}) {
        font-size: 1.5em !important;
        width: 90%;
        overflow-y: hidden !important;

        svg {
          font-size: 1.3em;
          margin: 0.65em;
        }
      }

      @media (max-width: ${size.tablet}) {
        font-size: 1em !important;
        overflow-y: hidden !important;

        svg {
          font-size: 1.7em;
          margin: 0.65em;
        }
      }

      @media (max-width: ${size.mobileL}) {
        font-size: 0.75em !important;
        overflow-y: hidden !important;

        svg {
          font-size: 1.7em;
          margin: 0.65em;
        }
      }
    }
  }
`

export default function Description() {
  return (
    <DescriptionStyle>
      <div className="bg">
        <div className="icons">
          <BsCupStraw />
          <BsBag />
          <FaGifts />
          <FaTshirt />
          <FaGlasses />
          <FaFilm />
          <FaSocks />
          <TbHanger />
          <AiOutlineCoffee />
          <BsCameraReels />
          <AiOutlineShopping />
          <BsCupStraw />
          <BsBag />
          <FaGifts />
          <FaTshirt />
          <FaGlasses />
          <FaFilm />
          <FaSocks />
          <TbHanger />
          <AiOutlineCoffee />
          <BsCameraReels />
          <AiOutlineShopping />
          <BsCupStraw />
          <BsBag />
          <FaGifts />
          <FaTshirt />
          <FaGlasses />
          <FaFilm />
          <FaSocks />
          <TbHanger />
          <AiOutlineCoffee />
          <BsCameraReels />
          <AiOutlineShopping />
          <BsCupStraw />
          <BsBag />
          <FaGifts />
          <FaTshirt />
          <FaGlasses />
          <FaFilm />
          <FaSocks />
          <TbHanger />
          <AiOutlineCoffee />
          <BsCameraReels />
          <AiOutlineShopping />
          <BsCupStraw />
          <BsBag />
          <FaGifts />
          <FaTshirt />
          <FaGlasses />
          <FaFilm />
          <FaSocks />
          <TbHanger />
          <AiOutlineCoffee />
          <BsCameraReels />
          <AiOutlineShopping />
          <BsCupStraw />
          <BsBag />
          <FaGifts />
          <FaTshirt />
          <FaGlasses />
          <FaFilm />
          <FaSocks />
          <TbHanger />
          <AiOutlineCoffee />
          <BsCameraReels />
          <AiOutlineShopping />
          <BsCupStraw />
          <BsBag />
          <FaGifts />
          <FaTshirt />
          <FaGlasses />
          <FaFilm />
          <FaSocks />
          <TbHanger />
          <AiOutlineCoffee />
          <BsCameraReels />
          <AiOutlineShopping />
        </div>
      </div>
      <div>
        <img src="/logo-white.png" />
        <h1>Reel Crew Gifts</h1>
      </div>
      <h2>
        Whether it's for a dozen or a thousand, we are your{" "}
        <strong style={{ color: "#7ad4f8", textDecoration: "underline" }}>
          quick and easy
        </strong>{" "}
        one-stop shop for custom wrap/crew gifts! We will assist you with
        finding your perfect crew gift by helping you with product selection,
        your best design options, and we’ll do the work of securing the best
        pricing – all with our turn-key order process.
      </h2>
    </DescriptionStyle>
  )
}
