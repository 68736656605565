import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import styled from "styled-components"
import { size } from "../util/breakpoints"

const PartnersStyle = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;

  h1 {
    margin: 0px auto;
    padding: 50px 0;
    color: #ddd;
    font-size: 4.6em !important;
    background: transparent;
    width: 100%;
    text-align: center;

    &.studio {
      background: #222 !important;
    }

    @media (max-width: ${size.laptop}) {
      font-size: 3em !important;
      padding: 0.5em 0 1em 0;
    }

    @media (max-width: ${size.mobileL}) {
      font-size: 2em !important;
      padding: 1em 0 1em 0;
    }
  }

  .section {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    &.studio {
      background: #222;
      padding: 0 0 5em 0;
      margin: 0 !important;
      width: 100%;

      @media (max-width: ${size.laptopL}) {
        font-size: 1em !important;
        padding: 0 0 4em 0;
      }

      @media (max-width: ${size.laptop}) {
        font-size: 1em !important;
        padding: 0 0 4em 0;
      }
    }

    @media (max-width: ${size.laptop}) {
      font-size: 1em !important;
      width: 95%;
    }

    .company {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.studio {
        min-width: 230px;
        margin: 0em 2em;
        padding: 0;

        @media (max-width: ${size.laptop}) {
          min-width: 200px;
          margin: 0 1.25em 0 1.25em;
        }

        @media (max-width: ${size.tablet}) {
          min-width: 180px;
          max-width: 40%;
          margin: 0.4em 0.7em;
        }

        @media (max-width: ${size.mobileL}) {
          min-width: 150px;
          max-width: 40%;
          max-height: 250px;
          min-height: 150px;
          margin: 0.4em 0.75em;
        }
      }

      &.production {
        margin: 0 1.8em;
        min-width: 10vw;
        min-height: 150px;

        @media (max-width: ${size.laptop}) {
          margin: 1.25em 2em;
          min-width: 17vw;
          min-height: 110px;
        }

        @media (max-width: ${size.tablet}) {
          margin: 0 1.3em;
          min-width: 23vw;
          min-height: 120px;
        }

        @media (max-width: ${size.mobileL}) {
          margin: 0.5em 1.2em;
          min-width: 30vw;
          min-height: 80px;
        }
      }
    }

    .gatsby-image-wrapper {
      width: 100%;
      /* height: 200px; */
      /* padding: 20px; */
      // overflow: hidden;

      img {
      }
    }
  }
`

export default function Partners({ bg, color, title }) {
  const partnerData = useStaticQuery(graphql`
    query MyQuery {
      allJson {
        edges {
          node {
            name
            filename
            id
            size
          }
        }
      }
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/(logos)/" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const logoImages = partnerData
    ? partnerData.allFile.edges.map(m => ({ ...m.node }))
    : []

  const formattedPartners = partnerData
    ? partnerData.allJson.edges.map(m => {
        return {
          ...m.node,
          image: m.node.filename
            ? logoImages.find(
                f => f.base.toLowerCase() === m.node.filename.toLowerCase()
              )
              ? logoImages.find(
                  f => f.base.toLowerCase() === m.node.filename.toLowerCase()
                )
              : null
            : null,
        }
      })
    : []

  return (
    <PartnersStyle style={{ background: bg }}>
      <h1 className="studio">Our Clients:</h1>
      <div className="section studio">
        {formattedPartners
          .filter(f => f.size === "studio")
          .map(p => {
            return (
              p.image && (
                <div className={`company studio`}>
                  <GatsbyImage
                    durationFadeIn={400}
                    fadeIn
                    alt={`${p.name} Logo`}
                    fluid={p.image.childImageSharp.fluid}
                  />
                  {/* <p>{p.name}</p> */}
                </div>
              )
            )
          })}
      </div>
      <div className="section production">
        <h1>Productions:</h1>
        {formattedPartners
          .filter(f => f.size !== "studio")
          .map(p => {
            return (
              p.image && (
                <div className={`company production`}>
                  <GatsbyImage
                    durationFadeIn={400}
                    fadeIn
                    alt={`${p.name} Logo`}
                    fluid={p.image.childImageSharp.fluid}
                  />
                  {/* <p>{p.name}</p> */}
                </div>
              )
            )
          })}
      </div>
      {/* <div className="section production">
        {formattedPartners
          .filter(f => f.size === null)
          .map(p => {
            return (
              p.image && (
                <div className={`company production`}>
                  <GatsbyImage
                    durationFadeIn={400}
                    fadeIn
                    alt={`${p.name} Logo`}
                    fluid={p.image.childImageSharp.fluid}
                  />
                  <p>{p.name}</p>
                </div>
              )
            )
          })}
      </div> */}
    </PartnersStyle>
  )
}
