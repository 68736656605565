import * as React from "react"
import styled from "styled-components"
import { size } from "../util/breakpoints"
import { FaQuoteLeft, FaQuoteRight, FaTrophy } from "react-icons/fa"

const GoalStyle = styled.div`
  width: 100%;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 7em 3em;
  background: #256985;

  @media (max-width: ${size.laptopL}) {
    padding: 3em 1em;
  }

  @media (max-width: ${size.tablet}) {
    padding: 3em 1em;
  }

  @media (max-width: ${size.mobileL}) {
    padding: 2em 0.15em;
  }

  h2 {
    color: #eee;
    width: 65%;
    margin: 0;
    z-index: 999;
    font-size: 1.7vw !important;
    text-align: center;
    text-shadow: 2px 2px 3px #111;

    @media (max-width: ${size.laptopL}) {
      font-size: 2.5vw !important;
      width: 85%;
      margin: 1.5em 0;
    }

    @media (max-width: ${size.tablet}) {
      font-size: 2.8vw !important;
      margin: 0.8em 0;
    }

    @media (max-width: ${size.mobileL}) {
      font-size: 1.3em !important;
    }
  }

  svg.bg {
    fill: #111;
    height: 50%;
    width: 80%;
    padding: 0;
    margin: 0px !important;
    opacity: 0.35;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default function Goal({ bg, color, title }) {
  return (
    <GoalStyle style={{ background: bg }}>
      <FaTrophy className="bg" />
      <h2>
        Our goal is to provide you with premium-level crew gifts with
        high-quality custom decorations, on-time with superior customer service
        from start-to-finish. We are here to help you create memorable and
        long-lasting crew gifts through the power of customizable items no
        matter the size of your production.
      </h2>
    </GoalStyle>
  )
}
