import * as React from "react"
import styled from "styled-components"
import Layout from "@components/layout"
import Seo from "@components/seo"
import Partners from "@components/Partners"
import Goal from "@components/Goal"
import "@fontsource/open-sans-condensed"
import Description from "@components/Description"
import { size } from "../util/breakpoints"

const LayoutStyle = styled.div`
  flex-direction: column;
  margin: 0 auto;
  background: #2e2f30;

  @media (max-width: ${size.laptop}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <LayoutStyle>
      <Description />
      <Goal />
      {/* <About/> */}
      <Partners />
    </LayoutStyle>
  </Layout>
)

export default IndexPage
